import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":33,"disabled":_vm.disabled,"transition":"scale-transition","min-width":"288px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"placeholder":_vm.placeholder || _vm.label,"prepend-icon":_vm.prependIcon,"rules":_vm.isRequired ? [_vm.date ? (_vm.time ? true : '请选择时间！') : '请选择日期！'] : [],"disabled":_vm.disabled,"hide-details":_vm.hideDetails,"readonly":"","outlined":"","dense":"","append-icon":_vm.dateTime ? 'mdi-close' : ''},on:{"click":function($event){_vm.openFlag = 1},"click:append":_vm.clearDateTime},model:{value:(_vm.dateTime),callback:function ($$v) {_vm.dateTime=$$v},expression:"dateTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuActive),callback:function ($$v) {_vm.menuActive=$$v},expression:"menuActive"}},[_c('div',[(_vm.openFlag === 1)?_c(VDatePicker,{attrs:{"color":"primary","locale":"zh-cn","disabled":_vm.disabled,"allowed-dates":_vm.allowDates ? _vm.allowDates : _vm.allowDateParse},on:{"input":function($event){_vm.openFlag = 2},"change":function($event){_vm.dateTimeModelCmp = _vm.dateTime},"close":function($event){_vm.dateTimeModelCmp = _vm.dateTime}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}):_vm._e(),(_vm.openFlag === 2)?_c(VTimePicker,{attrs:{"color":"primary","format":"24hr","full-width":"","disabled":_vm.disabled,"allowed-hours":_vm.allowHours ? _vm.allowHours : _vm.allowHourParse,"allowed-minutes":_vm.allowMinutes ? _vm.allowMinutes : _vm.allowMinuteParse,"use-seconds":_vm.seconds,"scrollable":_vm.scrollable},on:{"input":function($event){_vm.dateTimeModelCmp = _vm.dateTime},"close":function($event){_vm.dateTimeModelCmp = _vm.dateTime}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }