import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,_vm._g(_vm._b({tag:"component",attrs:{"close-on-content-click":true,"transition":"scale-transition","max-width":"290px","min-width":"auto","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({tag:"component",attrs:{"label":_vm.label,"placeholder":_vm.label,"rules":_vm.rules,"readonly":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'component',_vm.extendProps(attrs, _vm.textFieldProps.componentProps),false),_vm.extendProps(on, _vm.textFieldProps.eventHandlers)))]}}]),model:{value:(_vm.menuActive),callback:function ($$v) {_vm.menuActive=$$v},expression:"menuActive"}},'component',_vm.menuProps.componentProps,false),_vm.menuProps.eventHandlers),[_c(VDatePicker,_vm._g(_vm._b({tag:"component",attrs:{"color":"primary","locale":"zh-cn","type":_vm.type,"no-title":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'component',_vm.datePickerProps.componentProps,false),_vm.datePickerProps.eventHandlers))],1)}
var staticRenderFns = []

export { render, staticRenderFns }